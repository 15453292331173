import React, { Component } from 'react';
import * as SurveyJS from 'survey-react';
import data from '../survey.json';
import LoadingAnimation from '../animations/loading/LoadingAnimation';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import Expired from './Expired';
import { BASE_URL } from './config';

const SOURCES = {
  Whatsapp: 'vía Whatsapp',
  "2BGlobal Inbound": '',
	"App Store": '',
	"AppStore": '',
	"Controversias": '',
	"Instagram": 'vía Instagram',
	"Prevención de fraude": '',
	"Staff": '',
	"Supply Chain": '',
	"Unidad Support & Beyond": '',
	"Web": ''
}

moment.locale('es');
//console.log(`${JSON.stringify(SurveyJS.defaultStandardCss)}`)
SurveyJS.defaultStandardCss.container = ""
SurveyJS.defaultStandardCss.footer = "survey-nav"
SurveyJS.defaultStandardCss.comment = "comment-question"
SurveyJS.defaultStandardCss.navigation.next = "action-button"
SurveyJS.defaultStandardCss.navigation.complete = "action-button"
SurveyJS.defaultStandardCss.question.mainRoot = "question"
SurveyJS.defaultStandardCss.question.title = "question-title"
SurveyJS.defaultStandardCss.question.content = "question-input"
SurveyJS.defaultStandardCss.question.hasError = "errored"
SurveyJS.defaultStandardCss.error.root = "question-error"
SurveyJS.defaultStandardCss.rating.root = "likert-question"
// SurveyJS.defaultStandardCss.rating.item = "nps-button w-radio"
// SurveyJS.defaultStandardCss.rating.itemText = "nps-label w-form-label"
SurveyJS.defaultStandardCss.rating.item = "radio-button w-radio"
SurveyJS.defaultStandardCss.rating.itemText = "radio-button-label w-form-label"
SurveyJS.defaultStandardCss.rating.selected = "active"
SurveyJS.defaultStandardCss.rating.minText = "left-label"
SurveyJS.defaultStandardCss.rating.maxText = "right-label"


const Loading = ({message}) => (
    <div className="card-body text-center" >
        <LoadingAnimation />
        <div className="mt-4">{message}</div>
    </div >
);


export default class Survey extends Component {

    state = {
        isLoading: true,
        answers: {},
        timer: null,
        error: null,
    }

    componentDidMount() {
        const { params } = this.props.match;
        const id = params.id;
        const items = this.props.location.search.replace("?", "").split("&");
        var queryParams = {};
        items.forEach((p) => {
            const [key, value] = p.split("=");
            queryParams[key] = value;
        });
        this.setState({
            id,
            ...queryParams
        });
        if (params.conversationId) {
          return this.createIxAndLoadSurvey(params)
        }
        this.loadSurvey(id);
    }

    componentWillUnmount = () => {
        const { timer } = this.state;
        if (timer !== null) {
            clearTimeout(timer);
        }
    }

    loadSurvey = async (id) => {
        try {
            const response = await axios.get(`${BASE_URL}/invitation/survey/${id}`);
            const data = response.data;
            const m = moment(data.response.ticketDate);
            const metadata = {
                "date": m.format("DD [de] MMMM"),
                "media": SOURCES[data.response.ticketChannel] || data.response.ticketChannel,
                "agent": data.response.ticketAgentName
            };
            this.setState({
                isLoading: false,
                surveyData: data.survey.pages,
                metadata: metadata,
                responseId: data.response["_id"],
                surveyId: data.survey["_id"],
                collector: data.response.collector
            });
        }
        catch (ex) {
            console.error(`[Survey.js] loadSurvey:: ${JSON.stringify(ex.response.data)}`);
            this.setState({
                isLoading: false,
                error: ex.response.data.message
            });
        }
    }

    createIxAndLoadSurvey = async ({ conversationId, userId, ixDate, ixTopic }) => {

      let URL = `${BASE_URL}/web/interaction`
      let body = {
        survey: '5f10be84dfc8a23be8fca484',
        conversationId,
        userId,
        interactionDate: moment(ixDate, 'YYMMDDhhmm'),
        reasonId: ixTopic
      }

      if (!userId) {
        URL = `${BASE_URL}/web/conversation`
        delete body.userId
        delete body.interactionDate
        delete body.reasonId
      }

      try {
        const ixResponse = await axios.post(URL, body);
        const { token: responseToken, type: responseType } = ixResponse.data
        if (!responseToken) throw new Error('No se pudo crear encuesta')
        const response = await axios.get(`${BASE_URL}/invitation/survey/${responseToken}`);
        const data = response.data;
        const m = moment(data.response.ticketDate);
        let metadata = {
            "date": m.format("DD [de] MMMM"),
            "media": SOURCES[data.response.ticketChannel] || data.response.ticketChannel,
            "agent": data.response.ticketAgentName
        };

        if (responseType === 'resume') {
          metadata = Object.assign({}, metadata, data.response)
          console.log(metadata);
        }

        this.setState({
            isLoading: false,
            surveyData: data.survey.pages,
            metadata: metadata,
            responseId: data.response["_id"],
            surveyId: data.survey["_id"],
            collector: data.response.collector
        });
      } catch (ex) {
          console.error(`[Survey.js] loadSurvey:: ${JSON.stringify(ex)}`);
          this.setState({
              isLoading: false,
              error: 'Esta encuesta ya no está disponible. Te invitamos a darnos tu opinión en tu próximo contacto.'
          });
      }
    }

    /**
       * The event is fired after a user clicks the 'Complete' button and finishes a survey. Use this event to send the survey data to your web server.
       * sender - the survey object that fires the event.
       * options.showDataSaving(text) - call this method to show that the survey is saving survey data on your server. The text is an optional parameter to show a custom message instead of default.
       * options.showDataSavingError(text) - call this method to show that an error occurred while saving the data on your server. If you want to show a custom error, use an optional text parameter.
       * options.showDataSavingSuccess(text) - call this method to show that the data was successfully saved on the server.
       * options.showDataSavingClear - call this method to hide the text about the saving progress.
       */
    onComplete = (survey, options) => {
        const { answers } = this.state;
        this.setState({sending: true});
        this.updateSurveyAnswers(answers, true);
    }

    /**
    * Validate a question
    * The event is fired on validating value in a question. You can specify a custom error message using options.error.
    * The survey blocks completing the survey or going to the next page when the error messages are displayed.
    * sender - the survey object that fires the event.
    * options.question - a validated question.
    * options.name - a question name.
    * options.value - the current question value (answer).
    * options.error - an error string. It is empty by default.
    */
    onValidateQuestion = (survey, options) => {
        const { answers } = this.state;
        answers[options.name] = options.value;
        this.setState({answers});
        this.updateSurveyAnswers(answers, false);
    }

    setRatingClasses = (survey, options) => {
      if (options.question.getType() !== 'rating') return;
      if (options.question.rateMax === 10) {
        options.cssClasses.item = 'nps-button';
        options.cssClasses.itemText = 'nps-label';
      }
      if (options.question.rateMax === 5) {
        options.cssClasses.item = 'radio-button';
        options.cssClasses.itemText = 'radio-button-label';
      }
    }

    updateSurveyAnswers = async (answers, isComplete) =>  {
        const { surveyId, responseId, collector } = this.state;
        const { history } = this.props;
        const body = {
            ...answers,
            survey: surveyId,
            collector: collector
        };
        try {
            await axios.post(`${BASE_URL}/response/${responseId}/survey`, body);
            if (isComplete) {
                history.replace('/gracias');
            }
        }
        catch(ex) {
            if (isComplete) {
                this.setState({
                    timer: setTimeout(() => { this.updateSurveyAnswers(answers, isComplete); }, 1000)
                });
            }
        }
    }

    render() {
        const { isLoading, metadata, sending, error } = this.state;
        var message = isLoading ? "Cargando Encuesta..." :  sending ? "Enviando Encuesta..." : null;

        return (
            <div className="question">
                {message !== null ? <Loading message={message} /> :
                    error !== null ? <Expired message={error} /> :
                        <SurveyJS.Survey
                            json={data}
                            data={metadata}
                            onComplete={this.onComplete}
                            onCurrentPageChanged={this.onCurrentPageChanged}
                            onValidateQuestion={this.onValidateQuestion}
                            onUpdateQuestionCssClasses={this.setRatingClasses}
                        />
                }
            </div>
        );
    }
}
