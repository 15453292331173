import React, { Component } from 'react';
import * as SurveyJS from 'survey-react';
import data from '../survey.json';
import LoadingAnimation from '../animations/loading/LoadingAnimation';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/es';
import Expired from './Expired';

moment.locale('es');
//console.log(`${JSON.stringify(SurveyJS.defaultStandardCss)}`)
SurveyJS.defaultStandardCss.container = ""
SurveyJS.defaultStandardCss.footer = "survey-nav"
SurveyJS.defaultStandardCss.comment = "comment-question"
SurveyJS.defaultStandardCss.navigation.next = "action-button"
SurveyJS.defaultStandardCss.navigation.complete = "action-button"
SurveyJS.defaultStandardCss.question.mainRoot = "question"
SurveyJS.defaultStandardCss.question.title = "question-title"
SurveyJS.defaultStandardCss.question.content = "question-input"
SurveyJS.defaultStandardCss.question.hasError = "errored"
SurveyJS.defaultStandardCss.error.root = "question-error"
SurveyJS.defaultStandardCss.rating.root = "likert-question"
// SurveyJS.defaultStandardCss.rating.item = "nps-button w-radio"
// SurveyJS.defaultStandardCss.rating.itemText = "nps-label w-form-label"
SurveyJS.defaultStandardCss.rating.item = "radio-button w-radio"
SurveyJS.defaultStandardCss.rating.itemText = "radio-button-label w-form-label"
SurveyJS.defaultStandardCss.rating.selected = "active"
SurveyJS.defaultStandardCss.rating.minText = "left-label"
SurveyJS.defaultStandardCss.rating.maxText = "right-label"


const Loading = ({message}) => (
    <div className="card-body text-center" >
        <LoadingAnimation />
        <div className="mt-4">{message}</div>
    </div >
);

const Config = ({ context, handleChange, startSurvey }) => (
  <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '320px', margin: '0 auto' }}>
    <h3 style={{ marginBottom: '0.5em' }}>Modo de prueba</h3>
    <p style={{ marginBottom: '1.5em', fontSize: '75%' }}>Configura las variables de personalización</p>
    <label>Agente</label>
    <input name="agent" value={context.agent} onChange={handleChange} placeholder="Nombre de Agente" style={{ marginBottom: '1.5em' }} />
    <label>Fecha de interacción</label>
    <input name="date" value={context.date} onChange={handleChange} placeholder="Fecha de interacción" style={{ marginBottom: '1.5em' }} />
    <label>Canal de interacción</label>
    <select name="media" value={context.media} onChange={handleChange} style={{ marginBottom: '1.5em' }}>
      <option value="">Selecciona un canal de interacción</option>
      <option value="mediante llamada telefónica">mediante llamada telefónica</option>
      <option value="vía correo electrónico">vía correo electrónico</option>
      <option value="vía chat">vía chat</option>
      <option value="Top Accounts">Top Accounts</option>
    </select>
    <input type="button" onClick={startSurvey} value="Comenzar" className="action-button" />
  </div>
)

export default class DemoSurvey extends Component {

    state = {
        activeView: 'CONFIG',
        isLoading: false,
        answers: {},
        timer: null,
        error: null,
        context: {
          agent: 'Abigail Mireles',
          media: 'Top Accounts',
          date: '1 de Agosto'
        }
    }

    handleChange = ({ target: { name, value } }) => {
      console.log(name, value);
      this.setState((prev) => ({
        context: {
          ...prev.context,
          [name]: value
        }
      }))
    }

    startSurvey = () => {
      const { context: c } = this.state
      if (!c.agent || !c.date || !c.media) return alert('Por favor ingresa valores de prueba')
      this.setState({
        activeView: 'SURVEY'
      })
    }

    /**
       * The event is fired after a user clicks the 'Complete' button and finishes a survey. Use this event to send the survey data to your web server.
       * sender - the survey object that fires the event.
       * options.showDataSaving(text) - call this method to show that the survey is saving survey data on your server. The text is an optional parameter to show a custom message instead of default.
       * options.showDataSavingError(text) - call this method to show that an error occurred while saving the data on your server. If you want to show a custom error, use an optional text parameter.
       * options.showDataSavingSuccess(text) - call this method to show that the data was successfully saved on the server.
       * options.showDataSavingClear - call this method to hide the text about the saving progress.
       */
    onComplete = (survey, options) => {
        const { history } = this.props;
        console.log('Survey data', JSON.stringify(survey.data, null, 2));
        return history.replace('/test-complete');
    }

    setRatingClasses = (survey, options) => {
      if (options.question.getType() !== 'rating') return;
      if (options.question.rateMax === 10) {
        options.cssClasses.item = 'nps-button';
        options.cssClasses.itemText = 'nps-label';
      }
      if (options.question.rateMax === 5) {
        options.cssClasses.item = 'radio-button';
        options.cssClasses.itemText = 'radio-button-label';
      }
    }

    onValidateQuestion = (survey, options) => {
        console.log(options.name, options.value);
    }

    render() {
        const { isLoading, metadata, sending, error, context, activeView } = this.state;
        var message = isLoading ? "Cargando Encuesta..." :  sending ? "Enviando Encuesta..." : null;

        if (activeView === 'CONFIG') {
          return (
            <Config
              context={context}
              handleChange={this.handleChange}
              startSurvey={this.startSurvey}
            />
          )
        }

        return (
            <div className="question">
                {message !== null ? <Loading message={message} /> :
                    error !== null ? <Expired message={error} /> :
                        <SurveyJS.Survey
                            json={data}
                            data={context}
                            onComplete={this.onComplete}
                            onValidateQuestion={this.onValidateQuestion}
                            onUpdateQuestionCssClasses={this.setRatingClasses}
                        />
                }
            </div>
        );
    }
}
