import React, { Component } from 'react';
import { Route, Link, Switch } from 'react-router-dom';

import PrivacyPolicy from './PrivacyPolicy';
import Gratitude from './Gratitude';
import Survey from './Survey';
import DemoSurvey from './DemoSurvey';
import DemoGratitude from './DemoGratitude';
window.$ = window.JQuery = require('jquery');

export default class App extends Component {

	render() {
		return (
			<>
				<header className="survey-header">
					<img src="/images/brand-logo-white.png" width="80" height="80" alt="" />
				</header>
				<main className="survey-main">
					<div className="container">
						<Switch>
							<Route exact path="/aviso-de-privacidad" component={PrivacyPolicy} />
							<Route exact path="/gracias" component={Gratitude} />
							<Route exact path="/test-complete" component={DemoGratitude} />
							<Route exact path="/test-mode" component={DemoSurvey} />
							<Route exact path="/d/:conversationId" component={Survey} />
							<Route exact path="/d/:conversationId/:userId/:ixDate/:ixTopic" component={Survey} />
							<Route exact path="/:id" component={Survey} />
						</Switch>
					</div>
				</main>
				<Switch>
					<Route exact path="/aviso-de-privacidad" component={null} />
					<Route>
						<footer className="survey-footer">
							<div className="container">
								<Link to="/aviso-de-privacidad" target="_blank">
									Aviso de Privacidad
								</Link>
							</div>
						</footer>
					</Route>
				</Switch>
			</>
		);
	}

}
