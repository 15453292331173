import React, { Component } from 'react';
import Lottie from 'react-lottie';
import data from './loading-spinner.json'

const defaultOptions = {
  loop: true,
  renderer: 'svg',
  autoplay: true,
  animationData: data,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
}

export default class LoadingAnimation extends Component {

  render() {
    return null;
    return(
      <div style={{ marginBottom: '24px' }}>
        <Lottie
          options={defaultOptions}
          width={64}
          height={64}
        />
      </div>
    );
  }
}
