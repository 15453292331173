import React, { Component } from 'react';
import ExpiredAnimation from '../animations/expired/ExpiredAnimation'

export default class Expired extends Component {

    render() {
        var { message } = this.props;
        message = message || "La encuesta ya no está disponible, te enviaremos un enlace en tu próxima interacción con nuestro equipo de Customer Happiness."
        return (
          <div className="message">
            <img src="/images/Stickers-WA_21.png" srcSet="/images/Stickers-WA_21.png 500w, /images/Stickers-WA_21.png 512w" sizes="(max-width: 479px) 104px, 160px" alt="" className="message-sticker-small" />
            <div className="message-title">Lo sentimos</div>
            <div className="message-text">{ message }</div>
          </div>
        );
    }
}
