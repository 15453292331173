import React, { Component } from 'react';
import './PrivacyPolicy.css'

export default class PrivacyPolicy extends Component {

	render() {
		return (
			<div className="mt-5"
			style={{
				maxWidth: "900px",
				marginRight: "auto",
				marginLeft: "auto",
			}}
			>
				<h2 className="title mb-5">Aviso de Privacidad</h2>
				<div className="editable">
					<p>Payclip S. de R.L. de C.V. (en lo sucesivo <strong>“CLIP”</strong>) publica el presente <strong>Aviso de Privacidad</strong> cumpliendo con lo que establece la <strong>Ley Federal de Protección de Datos Personales en Posesión de los Particulares</strong> (en lo sucesivo “LFPDPPP”) y demás regulaciones aplicables, bajo los siguientes términos:</p>
					<h2>RESPONSABLE DE LOS DATOS PERSONALES</h2>
					<p>Para efectos del presente Aviso de Privacidad, <strong>CLIP</strong> tiene su domicilio en Avenida Azcapotzalco número 696, int. 21 A 2, Colonia Azcapotzalco Centro, Alcaldía Azcapotzalco, C.P. 02000, Ciudad de México, México. <strong>CLIP</strong> es responsable de la obtención, divulgación, almacenamiento, uso, manejo, aprovechamiento, acceso, transferencia y/o disposición de los datos personales que recabe de sus Clientes (en lo sucesivo, el “Cliente”), observando en todo momento los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad.</p>
					<p>El Cliente acepta que ha leído, entendido y acordado el presente <strong>Aviso de Privacidad</strong> que <strong>CLIP</strong> ha puesto en su conocimiento.</p>
					<h2>DATOS PERSONALES RECABADOS</h2>
					<p>Los datos personales que <strong>CLIP</strong> solicita a sus Clientes son de manera enunciativa, más no limitativa: datos de identificación, datos informáticos, datos de geolocalización, datos de contacto entre ellos: laborales, comerciales, financieros y patrimoniales, así como datos de terceros, mismos que son recabados a través de nuestra página de internet <a href="https://clip.mx/">www.clip.mx</a>. Dichos datos son recabados directamente por el Cliente, a través de cualquier medio electrónico, óptico, sonoro, visual, o a través de cualquier otra tecnología desarrollada por <strong>CLIP</strong> con la finalidad de prestar sus servicios.</p>
					<h2>POLÍTICA DE DATOS SENSIBLES</h2>
					<p>Dentro de nuestras políticas comerciales, <strong>CLIP</strong> no solicita ninguna información que bajo la <strong>LFPDPPP</strong> se entiendan como datos sensibles, tales como religión, preferencias sexuales, opiniones políticas, entre otros. En <strong>CLIP</strong> apoyamos y fomentamos la diversidad; prohibimos cualquier acto discriminatorio o la solicitud de cualquier información que pudiera implicar un acto discriminatorio.</p>
					<h2>FINALIDAD DEL TRATAMIENTO DE LOS DATOS PERSONALES</h2>
					<p>Los datos que solicitamos de nuestros Clientes tienen los siguientes usos:</p>
					<ol>
						<li>A. Finalidades Primarias necesarias para los productos y servicios que solicita:</li>
					</ol>
					<ol>
						<li>1. Celebración de contratos para formalizar la prestación del servicio.</li>
						<li>2. Envío y recepción de lectores <strong>CLIP</strong>.</li>
						<li>3. Pago de transacciones a favor de nuestro Cliente.</li>
						<li>4. Emisión de facturas.</li>
						<li>5. Envío de vouchers, información de transacciones, pagos y facturas.</li>
					</ol>
					<ol>
						<li>B. Finalidades Secundarias no relacionadas con el cumplimiento de la relación jurídica que dio origen a los servicios contratados, pero que nos permiten y facilitan brindar a nuestros Clientes una mejor atención y servicio:</li>
					</ol>
					<ol>
						<li>1. Brindar asesoría y soporte técnico de nuestros productos y servicios.</li>
						<li>2. Generar un perfil para fines mercadotécnicos, publicitarios o de prospección comercial.</li>
						<li>3. Envío de información novedosa y de actualización de nuestros productos y servicios.</li>
						<li>4. Realización de encuestas de evaluación de calidad del servicio.</li>
						<li>5. Envío de publicidad de servicios, productos y líneas de negocio, propias y de nuestros socios comerciales.</li>
						<li>6. Comercializar nuevos productos y servicios.</li>
						<li>7. Brindar servicios promocionales, encuestas de servicio, calidad y satisfacción de nuestros clientes y el análisis de uso de productos financieros.</li>
						<li>8. Creación, integración, análisis, actualización y conservación de su expediente.</li>
					</ol>
					<h2>DATOS PERSONALES DE TERCEROS</h2>
					<p>El Cliente acepta y reconoce que, en caso de llevar a cabo el tratamiento de datos personales de terceros, se encuentra obligado en términos de la LFPDPPP a contar con su propio Aviso de Privacidad que le permita legalmente recabar, almacenar, usar, transferir, manejar y disponer de los datos personales y sensibles de sus propios clientes (en lo sucesivo “Terceros”).</p>
					<p>Asimismo, el Cliente se obliga a establecer en su Aviso de Privacidad la autorización de sus clientes, que le permita transferir los datos personales y sensibles de éstos en favor de <strong>CLIP</strong> con la finalidad de que en <strong>CLIP</strong> podamos tratar tales datos personales y sensibles para el cumplimiento de la prestación de servicios entre <strong>CLIP</strong> y el Cliente.</p>
					<p>El Cliente será el único responsable de cumplir con las obligaciones pactadas en el presente apartado, por lo que en caso que el Cliente incumpla y que por éste incumplimiento <strong>CLIP</strong> se vea afectado de manera directa o indirecta, el Cliente se obliga a sacar en paz y a salvo a <strong>CLIP</strong>, debiendo responder por cualquier daño o perjuicio que pudiera surgir en perjuicio de éste.</p>
					<p>Los datos personales recabados podrán ser tratados con la finalidad de que el Cliente pueda atender requerimientos legales de autoridades competentes.</p>
					<h2>OPCIONES Y MEDIOS QUE CLIP OFRECE A LOS CLIENTES PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS PERSONALES</h2>
					<p>Los datos personales de los Clientes serán tratados bajo la más estricta confidencialidad, cuidando las medidas de seguridad administrativas, físicas y técnicas que en <strong>CLIP</strong> implementemos, asi como en sus políticas y procedimientos de seguridad internos, evitando la posible divulgación ilícita de datos y limitando su uso a las Finalidades establecidas en el presente Aviso de Privacidad.</p>
					<h2>USO DE TECNOLOGÍAS DE RASTREO EN NUESTRO PORTAL DE INTERNET Y EN NUESTRA APLICACIÓN</h2>
					<p>Le informamos que en nuestra página de internet utilizamos cookies, web beacons u otras tecnologías de rastreo, a través de las cuales es posible monitorear su comportamiento como usuario de Internet, brindarle un mejor servicio y experiencia de usuario al navegar en nuestra página, así como ofrecerle nuevos productos y servicios basados en sus preferencias.</p>
					<p>Los datos personales que obtenemos de estas tecnologías de rastreo son los siguientes:</p>
					<h2>TRANSFERENCIA A TERCEROS</h2>
					<p>Los datos personales, financieros y patrimoniales del Cliente podrán ser transferidos a las instituciones financieras y/o a nuestros socios comerciales, filiales o subsidiarias del mismo grupo, y/o entidades públicas y privadas que regulen al sector financiero, así como a las autoridades correspondientes, de conformidad con las finalidades siguientes:</p>
					<ol>
						<li>A. Finalidades Primarias.</li>
					</ol>
					<ol>
						<li>1. Beneficios para nuevos servicios y/o productos que CLIP implemente de tiempo en tiempo para el Cliente.</li>
						<li>2. Creación, integración, análisis, actualización y conservación de su expediente.</li>
						<li>3. Acceso a promociones.</li>
						<li>4. Brindar una mejor experiencia al Cliente.</li>
						<li>5. Cumplimiento con la legislación y regulación aplicable.</li>
					</ol>
					<ol>
						<li>B. Finalidades Secundarias.</li>
					</ol>
					<ol>
						<li>1. Generar un perfil para fines mercadotécnicos, publicitarios o de prospección comercial.</li>
						<li>2. Envío de publicidad de servicios y productos financieros, así como líneas de negocio.</li>
						<li>3. Brindar servicios promocionales, encuestas de servicio, calidad y satisfacción de nuestros clientes y el análisis de uso de productos financieros.</li>
						<li>4. Brindar el servicio de navegación en el portal de los socios comerciales.</li>
						<li>5. Verificación y confirmación de su identidad.</li>
					</ol>
					<p>De igual forma, en relación con sus datos financieros y patrimoniales, obtenemos su autorización expresa conforme a lo siguiente:</p>
					<ul>
						<li>• Autorizo que mis datos financieros y patrimoniales sean tratados para las Finalidades descritas en el presente Aviso de Privacidad.</li>
						<li>• Autorizo que mis datos financieros y patrimoniales sean transferidos a terceros para las Finalidades establecidas de conformidad con el presente Aviso de Privacidad.</li>
					</ul>
					<p>La negativa para el uso de sus datos personales para las finalidades de carácter publicitarios no podrán ser un motivo para que le neguemos los servicios y productos que actualmente solicita o contrata con <strong>CLIP</strong>.</p>
					<p>En caso de transferencia de sus datos, el tercero receptor asumirá las mismas obligaciones que correspondan a <strong>CLIP</strong> para el tratamiento de datos personales, financieros y patrimoniales.</p>
					<h2>MEDIOS PARA EJERCER LOS DERECHOS DEL CLIENTE</h2>
					<p>En cualquier momento, el Cliente o, en su caso, su representante legal (debidamente acreditado) podrá ejercer los derechos de Acceso, Rectificación (cuando consideren que son erróneos o inexactos), Cancelación (en caso de que considere que los datos no son tratados de forma adecuada), Oposición (en caso de que no quiera que sus datos sean tratados para finalidades específicas) y/o Revocación (cuando ya no sea su deseo como Cliente que <strong>CLIP</strong> continúe usando sus datos personales). En adelante, estos derechos serán referidos en el presente Aviso de Privacidad bajo las iniciales “DERECHOS ARCO”.</p>
					<p>De la misma forma el Cliente puede revocar el consentimiento que, en su caso, nos haya otorgado y/o limitar el uso o divulgación de sus datos personales. El ejercicio de uno de ellos no impide el ejercicio del otro y ninguno es presupuesto para el ejercicio de otro.</p>
					<p>Para que el Cliente exija sus Derechos ARCO, deberá enviar su solicitud al correo electrónico <a href="mailto:privacidad@clip.mx">privacidad@clip.mx</a> y/o directamente a nuestro domicilio en Avenida Azcapotzalco número 696, int. 21 A 2, Colonia Azcapotzalco Centro, Alcaldía Azcapotzalco, C.P. 02000, Ciudad de México, México.</p>
					<p>La solicitud del ejercicio de los derechos del Cliente deberá incluir copia de su identificación y, en su caso, la del representante legal, así como contener los siguientes datos:</p>
					<ol>
						<li>1. Nombre del Cliente.</li>
						<li>2. Domicilio y dirección de correo electrónico para notificarle la respuesta a su solicitud.</li>
						<li>3. Descripción clara y precisa de los datos respecto de los cuales se busca ejercer el derecho correspondiente.</li>
						<li>4. Objeto de la solicitud.</li>
						<li>5. Cualquier elemento que facilite la localización de los datos.</li>
					</ol>
					<p>El plazo de respuesta de su solicitud será de 20 (veinte) días hábiles contados a partir del día en que se recibió la solicitud de los Derechos ARCO correspondiente. La respuesta será comunicada dentro del plazo antes mencionado a la dirección de correo electrónico que el Cliente proporcionó en su solicitud o enviando carta al domicilio. Una vez recibida, tendrá un plazo de 15 (quince) días hábiles para hacerla efectiva si procedió.</p>
					<p>En cualquier momento podrá revocar el consentimiento que ha otorgado a <strong>CLIP</strong> para el tratamiento de sus datos personales, de tal forma que dejemos hacer uso de los mismos. Para ello deberá presentar una solicitud en los términos mencionados para el ejercicio de los Derechos ARCO. El procedimiento a seguir será el mismo que el previsto para el ejercicio de los Derechos ARCO. En caso de ser procedente su solicitud, sus datos dejarán de ser tratados por <strong>CLIP</strong>.</p>
					<p>Es importante que tenga en cuenta que no en todos los casos podremos darle una respuesta favorable a su solicitud para ejercer Derechos ARCO, debido a que es posible que por alguna obligación legal <strong>CLIP</strong> requiera seguir tratando sus datos personales.</p>
					<h2>CAMBIOS AL AVISO DE PRIVACIDAD</h2>
					<p>El Aviso de Privacidad podrá ser modificado en cualquier momento con la finalidad de atender novedades legislativas, así como modificación de políticas internas de <strong>CLIP</strong>. Cualquier cambio será informado mediante alguno de los siguientes medios:</p>
					<ol>
						<li>1. Correo electrónico que enviemos.</li>
						<li>2. Nuestro sitio de internet <a href="https://clip.mx/">www.clip.mx</a></li>
					</ol>
					<p>Si se llegaren a modificar las finalidades para las cuales se tratan sus datos personales, en caso de ser necesario, se requerirá de nuevo el consentimiento del Cliente, ya sea expreso o tácito, atendiendo al caso particular.</p>
					<h2>INSTITUTO NACIONAL DE TRANSPARENCIA, ACCESO A LA INFORMACIÓN Y PROTECCIÓN DE DATOS PERSONALES</h2>
					<p>Si usted considera que su derecho a la protección de sus datos personales ha sido lesionado por alguna conducta u omisión de nuestra parte, o presume alguna violación a las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento y demás ordenamientos aplicables, podrá interponer su inconformidad o denuncia ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI). Para mayor información, le sugerimos visitar su página oficial de Internet <a href="http://www.inai.org.mx/">www.inai.org.mx</a>.</p>
					<h2>CONSENTIMIENTO DEL CLIENTE</h2>
					<p>De conformidad con la LFPDPPP existen supuestos en los cuales el consentimiento del Cliente no es necesario para el tratamiento de sus datos personales, por lo que CLIP no requerirá su autorización para el tratamiento de datos personales, patrimoniales o financieros para el cumplimiento de las Finalidades Primarias, necesarias para la prestación de los servicios de <strong>CLIP</strong>.</p>
					<p>En caso de que no esté de acuerdo, de manera parcial o total, en que sus datos personales (excepto financieros y patrimoniales, los cuales requieren de su autorización expresa) sean tratados o transferidos para las Finalidades previstas en el presente Aviso de Privacidad, solicitamos notifique su inconformidad y desacuerdo al correo electrónico <a href="mailto:privacidad@clip.mx">privacidad@clip.mx</a>.</p>
					<p>La negativa para el uso de sus datos personales para las finalidades de carácter publicitarios no podrán ser un motivo para que le neguemos los servicios y productos que actualmente solicita o contrata con <strong>CLIP</strong>.</p>
					<p>‍</p>
				</div>
			</div>
		);
	}
}