import React, { Component } from 'react';

export default class DemoGratitude extends Component {

    render() {
        return (
            <div className="message">
                <img
                  src="/images/Stickers-WA_17.png"
                  srcSet="/images/Stickers-WA_17.png 500w, /images/Stickers-WA_17.png 512w"
                  sizes="(max-width: 479px) 200px, 320px" alt=""
                  className="message-sticker"
                />
                <img
                  src="/images/CLCX_Thanks_2.png"
                  alt=""
                  className="message-sticker-bottom"
                />
                <a href="/test-mode" style={{ display: 'block', fontSize: '1.25em', marginTop: '2em' }}>Realizar otra prueba</a>
            </div>
        );
    }
}
